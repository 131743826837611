import { Layout, Menu, Drawer } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import logoPath from "../assets/MOM_logo.png"


const { Header } = Layout;
const TLMenu = (props) => {
  const [openMenu, setOpenMenu] = useState(false);

  const AppMenu = (isInline) => {
    <Menu
      theme="dark"
      mode={"inline"}
      style={{
        backgroundColor: "#1d1d1d",
        "&:hover": {
          backgroundColor: "#1d1d1d",
        },
      }}
    >
      <a href="https://www.tomorrowslegendsllc.com">
        <Menu.Item
          key="2"
          style={{
            backgroundColor: "#1d1d1d",
            "&:hover": {
              backgroundColor: "#1d1d1d",
            },
          }}
        ></Menu.Item>
      </a>
      <Link to="/foal-enrollment">
        <Menu.Item
          to="/foal-enrollment"
          key="2"
          style={{
            backgroundColor: "#1d1d1d",
            color: "#a36b53",
            fontSize: "large",
            "&:hover": {
              backgroundColor: "#1d1d1d",
            },
          }}
        >
          Foal Enrollment
        </Menu.Item>
      </Link>
      <Link to="/registration-lookup">
        <Menu.Item
          to="/registration-lookup"
          key="2"
          style={{
            backgroundColor: "#1d1d1d",
            color: "#a36b53",
            fontSize: "large",
            "&:hover": {
              backgroundColor: "#1d1d1d",
            },
          }}
        >
          Enrollment Lookup
        </Menu.Item>
      </Link>
      <Link to="/entry">
        <Menu.Item
          to="/entry"
          key="3"
          style={{
            backgroundColor: "#1d1d1d",
            color: "#a36b53",
            fontSize: "large",
            "&:hover": {
              backgroundColor: "#1d1d1d",
            },
          }}
        >
          Race Entry
        </Menu.Item>
      </Link>
      <Link to="/owner-change">
        <Menu.Item
          to="/owner-change"
          key="4"
          style={{
            backgroundColor: "#1d1d1d",
            color: "#a36b53",
            fontSize: "large",
            "&:hover": {
              color: "#58595a",
            },
          }}
        >
          Owner Change
        </Menu.Item>
      </Link>
    </Menu>;
  };

  return (
    <div>
      <Header
        style={{
          backgroundColor: process.env.REACT_APP_TEST_THEME
            ? process.env.REACT_APP_TEST_THEME
            : "#1d1d1d",
        }}
      >
        <div className="headerMenu">
          <Menu
            theme="dark"
            mode={"horizontal"}
            style={{
              backgroundColor: process.env.REACT_APP_TEST_THEME
                ? process.env.REACT_APP_TEST_THEME
                : "#1d1d1d",
              "&:hover": {
                backgroundColor: process.env.REACT_APP_TEST_THEME
                  ? process.env.REACT_APP_TEST_THEME
                  : "#1d1d1d",
              },
            }}
          >
            <a href="https://www.tlmamas.com">
              <Menu.Item
                key="1"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  "&:hover": {
                    backgroundColor: "#1d1d1d",
                  },
                }}
              >
                {" "}
                <img src={logoPath} style={{ height: "60px" }} alt="logo" />
              </Menu.Item>
            </a>
            <Link to="/foal-enrollment">
              <Menu.Item
                to="/foal-enrollment"
                key="2"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  // color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    backgroundColor: process.env.REACT_APP_TEST_THEME
                      ? process.env.REACT_APP_TEST_THEME
                      : "#1d1d1d",
                  },
                }}
              >
                Foal Enrollment
              </Menu.Item>
            </Link>
            {/*<Link to="/sire-enrollment">*/}
            {/*  <Menu.Item*/}
            {/*    to="/sire-enrollment"*/}
            {/*    key="2-sire"*/}
            {/*    style={{*/}
            {/*      backgroundColor: process.env.REACT_APP_TEST_THEME*/}
            {/*        ? process.env.REACT_APP_TEST_THEME*/}
            {/*        : "#1d1d1d",*/}
            {/*      color: "#a36b53",*/}
            {/*      fontSize: "large",*/}
            {/*      "&:hover": {*/}
            {/*        backgroundColor: process.env.REACT_APP_TEST_THEME*/}
            {/*          ? process.env.REACT_APP_TEST_THEME*/}
            {/*          : "#1d1d1d",*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Sire Enrollment | Renewal*/}
            {/*  </Menu.Item>*/}
            {/*</Link>*/}
            <Link to="/registration-lookup">
              <Menu.Item
                to="/registration-lookup"
                key="3"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  // color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    backgroundColor: "#1d1d1d",
                  },
                }}
              >
                Enrollment Lookup
              </Menu.Item>
            </Link>
            <Link to="/entry">
              <Menu.Item
                to="/entry"
                key="4"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  // color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    backgroundColor: "#1d1d1d",
                  },
                }}
              >
                Race Entry
              </Menu.Item>
            </Link>
            <Link to="/owner-change">
              <Menu.Item
                to="/owner-change"
                key="5"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  // color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    color: "#58595a",
                  },
                }}
              >
                Owner Change
              </Menu.Item>
            </Link>
            <Link to="/foal-update">
              <Menu.Item
                to="/foal-update"
                key="6"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  // color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    color: "#58595a",
                  },
                }}
              >
                Horse Info Update
              </Menu.Item>
            </Link>
            {process.env.REACT_APP_TEST_THEME && (
              <Link>
                <Menu.Item
                  to="/foal-update"
                  key="6"
                  style={{
                    backgroundColor: process.env.REACT_APP_TEST_THEME
                      ? process.env.REACT_APP_TEST_THEME
                      : "red",
                    color: "red",
                    fontSize: "large",
                    "&:hover": {
                      color: "#58595a",
                    },
                  }}
                >
                  TEST SITE
                </Menu.Item>
              </Link>
            )}
            <Link to="/results">
              <Menu.Item
                to="/results"
                key="7"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  // color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    color: "#58595a",
                  },
                }}
              >
                Results
              </Menu.Item>
            </Link>
            {/*<Link to="/earning">*/}
            {/*  <Menu.Item*/}
            {/*    to="/earning"*/}
            {/*    key="8"*/}
            {/*    style={{*/}
            {/*      backgroundColor: process.env.REACT_APP_TEST_THEME*/}
            {/*        ? process.env.REACT_APP_TEST_THEME*/}
            {/*        : "#1d1d1d",*/}
            {/*      // color: "#a36b53",*/}
            {/*      fontSize: "large",*/}
            {/*      "&:hover": {*/}
            {/*        color: "#58595a",*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Earnings*/}
            {/*  </Menu.Item>*/}
            {/*</Link>*/}
          </Menu>
        </div>
        <img src={logoPath} style={{ height: "60px" }} alt="logo" />

        <div
          className="menuIcon"
          style={{
            height: 60,
            paddingLeft: 12,
            paddingRight: 12,
            position: "absolute",
            right: 10,
            top: 5,
          }}
        >
          <MenuOutlined
            style={{
              fontSyle: "bolder",
              backgroundColor: process.env.REACT_APP_TEST_THEME
                ? process.env.REACT_APP_TEST_THEME
                : "#1d1d1d",
            }}
            onClick={() => {
              setOpenMenu(true);
            }}
          />
        </div>
        <Drawer
          open={openMenu}
          bodyStyle={{ backgroundColor: process.env.REACT_APP_TEST_THEME
              ? process.env.REACT_APP_TEST_THEME
              : "#1d1d1d" }}
          placement="right"
          closable={false}
          onClose={() => {
            setOpenMenu(false);
          }}
        >
          <img src={logoPath} style={{ height: "60px" }} alt="logo" />

          <Menu
            theme="dark"
            mode={"inline"}
            style={{
              backgroundColor: process.env.REACT_APP_TEST_THEME
                ? process.env.REACT_APP_TEST_THEME
                : "#1d1d1d",
              "&:hover": {
                backgroundColor: "#1d1d1d",
              },
            }}
          >
            <a href="https://www.tlmamas.com">
              <Menu.Item
                key="1"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  "&:hover": {
                    backgroundColor: "#1d1d1d",
                  },
                }}
              ></Menu.Item>
            </a>
            <Link to="/foal-enrollment">
              <Menu.Item
                to="/foal-enrollment"
                key="2"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  // color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    backgroundColor: "#1d1d1d",
                  },
                }}
              >
                Foal Enrollment
              </Menu.Item>
            </Link>
            {/*<Link to="/sire-enrollment">*/}
            {/*  <Menu.Item*/}
            {/*    to="/sire-enrollment"*/}
            {/*    key="2-sire"*/}
            {/*    style={{*/}
            {/*      backgroundColor: "#1d1d1d",*/}
            {/*      color: "#a36b53",*/}
            {/*      fontSize: "large",*/}
            {/*      "&:hover": {*/}
            {/*        backgroundColor: "#1d1d1d",*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Sire Enrollment | Renewal*/}
            {/*  </Menu.Item>*/}
            {/*</Link>*/}
            <Link to="/registration-lookup">
              <Menu.Item
                to="/registration-lookup"
                key="3"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  // color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    backgroundColor: "#1d1d1d",
                  },
                }}
              >
                Enrollment Lookup
              </Menu.Item>
            </Link>
            <Link to="/entry">
              <Menu.Item
                to="/entry"
                key="4"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  // color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    backgroundColor: "#1d1d1d",
                  },
                }}
              >
                Race Entry
              </Menu.Item>
            </Link>
            <Link to="/owner-change">
              <Menu.Item
                to="/owner-change"
                key="5"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  // color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    color: "#58595a",
                  },
                }}
              >
                Owner Change
              </Menu.Item>
            </Link>
            <Link to="/foal-update">
              <Menu.Item
                to="/foal-update"
                key="6"
                style={{
                  backgroundColor: process.env.REACT_APP_TEST_THEME
                    ? process.env.REACT_APP_TEST_THEME
                    : "#1d1d1d",
                  // color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    color: "#58595a",
                  },
                }}
              >
                Horse Info Update
              </Menu.Item>
            </Link>
            <Link to="/results">
              <Menu.Item
                to="/results"
                key="7"
                style={{
                  backgroundColor: "#1d1d1d",
                  color: "#a36b53",
                  fontSize: "large",
                  "&:hover": {
                    color: "#58595a",
                  },
                }}
              >
                Results
              </Menu.Item>
            </Link>
            {/*<Link to="/earning">*/}
            {/*  <Menu.Item*/}
            {/*    to="/earning"*/}
            {/*    key="8"*/}
            {/*    style={{*/}
            {/*      backgroundColor: "#1d1d1d",*/}
            {/*      color: "#a36b53",*/}
            {/*      fontSize: "large",*/}
            {/*      "&:hover": {*/}
            {/*        color: "#58595a",*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Earnings*/}
            {/*  </Menu.Item>*/}
            {/*</Link>*/}
          </Menu>
        </Drawer>
      </Header>
    </div>
  );
};

export default TLMenu;
